<template>
    <metainfo></metainfo>
    <section class="min-h-screen overflow-x-hidden">
        <header class="h-screen w-full mr-20 bg-black">
           <div class="film absolute flex bg-black h-screen w-screen">
                <div class="my-auto md:ml-10 xl:ml-24 z-20 md:border-l-4 caption px-4 md:px-8 border-gray-900 md:rounded-r-lg shadow-xl py-6 md:w-screen-40 3xl:w-screen/3 relative top-32">
                    <h5 class="uppercase text-lg lg:text-2xl text-white font-medium">
                        2020-2021 HIGHLIGHT REEL
                    </h5>
                </div>
            </div>
            <video
                muted
                id="comp-kcf8ajun_video" 
                class="_3vVMz" 
                role="presentation" 
                crossorigin="anonymous" 
                playsinline="" 
                preload="auto" 
                loop="" 
                autoplay="" 
                tabindex="-1" 
                width="100%" 
                height="100%" 
                src="https://video.wixstatic.com/video/e0ebe9_391d24f7501443aeac81009b4608423a/1080p/mp4/file.mp4" 
                style="width: 100%; height: 100%; object-fit: cover; object-position: center center; opacity: 1;">
            </video>
        </header>

        <div class="w-screen min-h-screen space-y-10 bg-gray-950">
            <!-- Music Videos & Movies -->
            <div class="lg:grid lg:grid-cols-2 lg:gap-4 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96">
                <div class="px-2">
                    <h1 class="uppercase text-2xl text-center font-bold text-indigo-600 py-10 lg:text-4xl">Music Videos</h1>
                    <div class="bg-white h-56 md:h-96 rounded-lg shadow-xl lg:shadow-lg">
                        <iframe
                            class="rounded-lg"
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube-nocookie.com/embed/videoseries?list=PLEuH2j3M-1Qvr5Qy6dB737ayt4TYRkHJl" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
                <div class="px-2">
                   <h1 class="uppercase text-2xl text-center font-bold text-indigo-600 py-10 lg:text-4xl">Movies</h1>
                   <div class="bg-white h-56 md:h-96 rounded-lg shadow-xl lg:shadow-lg">
                        <iframe
                            class="rounded-lg"
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube-nocookie.com/embed/videoseries?list=PLEuH2j3M-1Qt6wffMmZYOrUZigYt4BPuL" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                    </iframe>
                   </div>
                </div>
            </div>
            <!-- Shows -->
            <div class="pb-20 bg-gray-950 border-t-1 border-black">
                <h1 class="uppercase text-2xl text-center font-bold text-indigo-600 py-10 lg:text-4xl">Shows</h1>
                <div class="space-y-6 px-2">
                    <div class="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96">
                        <div class="bg-white h-56 md:h-96 rounded-lg shadow-xl lg:shadow-lg">
                            <iframe
                                class="rounded-lg"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/videoseries?list=PLEuH2j3M-1Qsixn8CRIenOerWQUEldjmw" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                        <div class="bg-white h-56 md:h-96 rounded-lg shadow-xl lg:shadow-lg">
                            <iframe
                                class="rounded-lg"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/videoseries?list=PLFOMBLH0TmmEiE3OnaCoYsfS-1ehpomq-" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                    <div class="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96">
                        <div class="bg-white h-56 md:h-96 rounded-lg shadow-xl lg:shadow-lg">
                            <iframe 
                                class="rounded-lg"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/videoseries?list=PLEuH2j3M-1Qtb14BGMtR9Agk5d38zJ0Nd" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                        <div class="bg-white h-56 md:h-96 rounded-lg shadow-xl lg:shadow-lg">
                            <iframe 
                                class="rounded-lg"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/videoseries?list=PL5zDzZZmZVUcgBAVE5iTpajxZCeIDBmCo" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Commercials -->
        <div class="pb-20 bg-gray-900">
            <h1 class="uppercase text-2xl text-center font-bold text-indigo-600 py-10 lg:text-4xl">Commercials</h1>
            <div class="px-2 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96">
                <div class="bg-white h-screen-60 rounded-lg shadow-xl lg:shadow-lg">
                    <iframe 
                        class="rounded-lg"
                        width="100%" 
                        height="100%" 
                        src="https://www.youtube.com/embed/videoseries?list=PLEuH2j3M-1QvADH_67mMWmVfylpMOvBRL" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Work',
    metaInfo() {
        return {
            title: 'Our Work | PlatinumTags Media',
            description: 'See our work.'
        }
    },
    data(){
        return {
            
        }
    },

    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
}
</script>

<style scoped>
    .film{
        background: rgb(0, 0, 0, 0.4);
    }

    .caption{
        background: rgb(0, 0, 0, .4);
    }
</style>